<template>
  <div>
    <strong class="my-2">{{name}}</strong>
    <div :id="id" :style="`width: ${width}; height: ${height};`"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { v4 as uuid } from 'uuid'
import theme from '@/libs/colorTheme'
echarts.registerTheme('kpmg', theme)

function delay (period) {
  return new Promise(resolve => {
    setTimeout(resolve, period)
  })
}

export default {
  components: {
  },
  props: ['nodes', 'links', 'categories', 'layout', 'name', 'width', 'height'],
  computed: {
  },
  created: async function () {
    await delay(10)
    this.load()
  },
  data () {
    return {
      id: uuid()
    }
  },
  methods: {
    load: async function () {
      const options = {
        tooltip: {},
        legend: [
          {
            data: this.categories.map(function (a) {
              return a.name
            })
          }
        ],
        series: [
          {
            type: 'graph',
            layout: this.layout ? this.layout : 'force',
            data: this.nodes,
            links: this.links,
            categories: this.categories,
            roam: true,
            label: {
              position: 'right'
            },
            force: {
              repulsion: 100
            }
          }
        ]
      }
      await delay(10)
      echarts.init(document.getElementById(this.id), 'kpmg').setOption(options)
    },
    reload: function () {
      console.log('triggered reload')
      echarts.dispose(document.getElementById(this.id))
      this.load()
    }
  },
  watch: {
    'nodes': 'reload',
    'links': 'reload',
    'categories': 'reload',
    'layout': 'reload',
    'name': 'reload'
  }
}
</script>

<style>
</style>
