<template>
  <div>
  <b-container fluid class="mb-3" v-if="!loading">
    <b-row v-if="$config.BUILD === 'full'" class="mb-4">
      <b-col>
        <b-button class="mr-3" v-if="permission.edit" variant="outline-primary" :to="{ name: 'FormEdit', params: { id: item.id, model: 'metric' }}">Edit</b-button>
        <span v-if="step.id < 0">
          <delete-standard :id="item.id" model="metric" @deleted="deleteSelf"></delete-standard>
        </span>
      </b-col>
      <b-col>
        <h4>Workflow</h4>
        <div>status: <strong>{{step.name}}</strong></div>
        <div v-if="item.workflow_created_by"><small>{{item.workflow_created_by}} at {{moment(item.workflow_created_at).format('YYYY-MM-DD HH:mm')}}</small></div>
        <div>
          <span v-if="!savingStep && permission.edit">
            <b-button class="mr-4" v-if="step.downStep" variant="outline-primary" size="sm" @click="stepUpdate(step.down)">move to: {{step.downStep.name}}</b-button>
            <b-button v-if="step.upStep" variant="outline-primary" size="sm" @click="stepUpdate(step.up)">move to: {{step.upStep.name}}</b-button>
          </span>
          <span v-if="savingStep">
            <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
          </span>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h4 class="card-title"><strong>{{item.name}}</strong></h4>
        <div v-if="item.taxonomy.length > 0">
         <in-line :rows="item.taxonomy"></in-line>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="item.definition">
      <b-col class="my-4 p-3 shadow bg-white">
          <div class="my-2">
            <strong>Definition</strong>
          </div>
          {{ item.definition }}
      </b-col>
    </b-row>
    <b-row>
      <b-col class="my-4 mr-4 p-3 shadow bg-white">
        <div class="my-2">
          <strong>Data elements</strong>
        </div>
        <div v-if="item.dataelements.length > 0">
          <div v-for="element in item.dataelements" :key="'dataelemenmt-' + item.id + '-' + element.id">
            <router-link :to="{ name: 'DataElement', params: { id: element.id }}">{{ element.name }}</router-link>
          </div>
        </div>
      </b-col>
      <b-col class="my-4 p-3 shadow bg-white">
        <div class="my-2">
          <strong>Reporting frameworks</strong>
        </div>
        <div v-if="item.reportingframeworks.length > 0">
          <div v-for="framework in item.reportingframeworks" :key="'framework-'+ item.id + '-' + framework.id">
            <router-link :to="{ name: 'MetricsReportingframework', params: { id: framework.id }}">{{ framework.name }}</router-link> - {{ framework.reference }}
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="relatedMetrics.length > 0" class="my-4 shadow p-3 bg-white">
      <b-col class="my-4">
        <strong>Related metrics with common data elements</strong>
        <div class="my-2" style="max-height: 40vh; overflow-y: auto;  overflow-x: auto;">
          <div v-for="metric in relatedMetrics" :key="'relate-dmetric-' + metric.id">
            <router-link :to="{ name: 'Metric', params: { id: metric.id }}">{{ metric.name }}</router-link>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row class="shadow p-3 bg-white">
      <b-col class="my-4">
        <div>
          <graph-simple :nodes="nodes" :links="links" :categories="categories" :layout="'force'" :name="'Metrics and data elements graph'" :width="'100%'" :height="'50vh'"></graph-simple>
        </div>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import ac from '@/libs/accesscontrol'
import delay from '@/libs/delay'
import InLine from '@/components/taxonomy/InLine.vue'
import GraphSimple from '@/components/charts/v1/graphSimple.vue'
import workflow from '@/workflows/metric'
import DeleteStandard from '@/components/DeleteStandard.vue'

export default {
  name: 'Metric',
  components: {
    DeleteStandard,
    InLine,
    GraphSimple
  },
  computed: {
    dataelements: function () {
      return this.$store.state.dataelements
    },
    metrics: {
      get () {
        return this.$store.state.metrics
      },
      set (payload) {
        this.$store.commit('setMetrics', payload)
      }
    },
    step: function () {
      const step = workflow.find(x => x.id === this.item.workflow_id)
      step.downStep = workflow.find(x => x.id === step.down)
      step.upStep = workflow.find(x => x.id === step.up)
      return step
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    this.permission.edit = ac.can(this.user.acgroups).updateAny('metric').granted
    this.load()
  },
  data () {
    const data = {
      item: {},
      loading: true,
      relatedMetrics: [],
      moment: moment,
      nodes: [],
      links: [],
      categories: [],
      permission: {
        delete: false,
        edit: false
      },
      savingStep: false
    }
    return data
  },
  methods: {
    deleteSelf: function () {
      // delete metric from this.metrics by this.item.id
      this.metrics = this.metrics.filter(x => x.id !== this.item.id)
      this.$router.push({ name: 'Metrics' })
    },
    load: async function () {
      this.loading = true
      this.relatedMetrics = []
      if (this.metrics.length === 0) {
        await this.loadMetric()
      } else {
        this.item = this.metrics.find(item => item.id === Number.parseInt(this.$route.params.id))
        if (this.item === undefined) {
          await this.loadMetric()
        }
      }
      this.item.dataelements.forEach(element => {
        this.links.push({ source: 'metric-' + this.item.id, target: 'dataelement-' + element.id, uuid: 'metric-' + this.item.id + 'dataelement-' + element.id })
      })
      this.nodes.push({ id: 'metric-' + this.item.id, name: this.item.name, category: 0, symbolSize: 20 })
      if (this.item.dataelements.length > 0) {
        const intersection = _.intersectionBy(this.dataelements, this.item.dataelements, 'id')
        intersection.forEach(row => {
          this.relatedMetrics = this.relatedMetrics.concat(row.metrics)
          row.metrics.forEach(metric => {
            this.links.push({ source: 'metric-' + metric.id, target: 'dataelement-' + row.id, uuid: 'metric-' + metric.id + 'dataelement-' + row.id })
          })
        })
        this.relatedMetrics = _.uniqBy(this.relatedMetrics, 'id')
        this.relatedMetrics = _.orderBy(this.relatedMetrics, ['name'], ['asc'])
        this.relatedMetrics.forEach(metric => {
          this.nodes.push({ id: 'metric-' + metric.id, name: metric.name, category: 2 })
        })
      }
      this.item.dataelements.forEach(element => {
        this.nodes.push({ id: 'dataelement-' + element.id, name: element.name, category: 1 })
      })
      this.nodes = _.uniqBy(this.nodes, 'id')
      this.links = _.uniqBy(this.links, 'uuid')
      this.categories = [
        {
          name: 'The metric'
        },
        {
          name: 'Data element'
        },
        {
          name: 'Related metric'
        }
      ]
      await delay(50)
      this.loading = false
      this.loadMetric()
    },
    loadMetric: async function () {
      try {
        const response = await this.$Amplify.API.get('cosmos', `/metric/${this.$route.params.id}`)
        this.item = response
        if (this.metrics.length > 0) {
          // update metric within metrics using id as a reference field
          const index = this.metrics.findIndex(x => x.id === this.item.id)
          if (index === -1) {
            this.metrics.push(this.item)
          } else {
            this.metrics[index] = this.item
          }
        }
      } catch (e) {
        this.$logger.warn('event loading error: ' + e)
      }
    },
    stepUpdate: async function (id) {
      this.savingStep = true
      try {
        const params = {
          body: {
            workflow_id: id,
            workflow_created_at: moment().toISOString(),
            workflow_created_by: this.user.username
          }
        }
        await this.$Amplify.API.put('cosmos', `/standard/metric/${this.item.id}`, params)
        this.item.workflow_id = id
        this.item.workflow_created_by = params.body.workflow_created_by
        this.item.workflow_created_at = params.body.workflow_created_at
      } catch (e) {
        this.$logger.warn('event saving error: ' + e)
      }
      this.savingStep = false
    }
  },
  watch: {
    '$route': function () {
      this.load()
    }
  }
}
</script>
